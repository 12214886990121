<template>
  <v-card v-if="editor" flat class="transparent">
    <v-card-text class="pa-0">
      <v-card class="pa-2" :outlined="false" flat color="transparent">
        <editor-content
          class="editor__content onSurface--text"
          :editor="editor"
        />
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script>
import "@/styles/tiptapStyle.css";
import "@/styles/tiptapStyle.scss";
import { Editor, EditorContent } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from "tiptap-extensions";
export default {
  components: {
    EditorContent,
  },
  props: {
    description: Array,
    menubar: Boolean,
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    initializeEditor() {
      this.editor = new Editor({
        editable: !this.readOnly,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({
            levels: [1, 2, 3],
            HTMLAttributes: {
              class: "wooz-header",
            },
          }),
          new Link({ rel: "", target: "_blank" }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code({}),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
        ],
        content: {
          type: "doc",
          content: this.description,
        },

        onUpdate: ({ getJSON }) => {
          this.$emit("updateContent", getJSON().content);
        },
      });
    },
    clearContent() {
      this.editor.clearContent();
    },
  },
};
</script>
