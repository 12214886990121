<template>
  <v-card
    class="rounded-lg py-3"
    :href="content.link"
    target="_blank"
    :ripple="false"
    flat
    color="#f3f3f3"
  >
    <v-img
      style="border-radius: 8px"
      :aspect-ratio="4 / 3"
      class="surface mx-3"
      :src="content.imageURL"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <!-- <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular> -->
        </v-row>
      </template>
    </v-img>

    <v-card-text v-if="$vuetify.breakpoint.mdAndUp" class="pb-0">
      <v-list class="transparent pa-0" :ripple="false" three-line>
        <v-list-item-title class="subtitle-2 font-weight-bold">
          {{ content.title }} <br />
          0원
        </v-list-item-title>
        <v-list-item-action-text class="caption">
          {{ content.createdAt | moment("YYYY-MM-DD ") }}
        </v-list-item-action-text>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    content: Object,
  },
};
</script>

<style></style>
