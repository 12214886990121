<template>
  <v-dialog
    class="elevation-0"
    v-model="dialog"
    :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
    scrollable
    max-width="1080px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div @click="showItem" v-bind="attrs" v-on="on">
        <portfolio-card :content="item" />
      </div>
    </template>
    <v-card flat class="rounded-xl">
      <v-card-title class="">
        <v-spacer></v-spacer>
        <v-btn
          class="onSurface--text mr-3"
          v-clipboard:copy="`https://madeall3d.com/page/portfolio/${item.id}`"
          v-clipboard:success="
            () => {
              onCopy();
            }
          "
          v-clipboard:error="
            () => {
              onCopyError();
            }
          "
          rounded
          icon
          :ripple="false"
        >
          <feather type="share-2" />
        </v-btn>
        <v-btn
          class="onSurface--text"
          @click="close"
          rounded
          icon
          :ripple="false"
        >
          <feather type="x-circle" />
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <span class="highlight title font-weight-bold onSurface--text">
                {{ item.title }}
              </span>
              <v-spacer></v-spacer>
            </v-col>
            <v-col v-if="item.imageURL" cols="12" md="6">
              <v-card class="surface rounded-xl" flat>
                <v-img :aspect-ratio="4 / 3" :src="item.imageURL" />
              </v-card>
            </v-col>
            <v-col class="mt-2" align-self="start" cols="12" md="6">
              <v-row>
                <v-col cols="4" md="2" align-self="center"> 게시일 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.createdAt | moment("YYYY-MM-DD HH:mm") }}
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 소요 비용 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  align-self="center"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.options.cost }}
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 작업 방식 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  align-self="center"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.options.method }}
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 작업 소재 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  align-self="center"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.options.material }}</v-col
                >
                <v-col cols="4" md="2" align-self="center"> 표면 가공 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  align-self="center"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.options.postprocess }}
                </v-col>
                <v-col cols="4" md="2" align-self="center"> 소요 기간 </v-col>
                <v-col
                  cols="8"
                  md="10"
                  align-self="center"
                  class="font-weight-bold onSurface--text"
                >
                  {{ item.options.time }}
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col class="pa-2" cols="12">
              <tiptap-viewer :description="item.content" :readOnly="true" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TiptapViewer from "@/components/Editor/TiptapViewer.vue";
import PortfolioCard from "@/components/Card/PortfolioCard.vue";

import _ from "lodash";
import constants from "@/utils/constants";

export default {
  components: { TiptapViewer, PortfolioCard },
  props: {
    content: Object,
  },
  created() {
    this.item = Object.assign(
      _.cloneDeep(constants.portfolioInterface),
      this.content,
    );
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    showItem() {
      this.dialog = true;
    },
    onCopy: function () {
      this.$toasted.global.success("클립보드에 저장했습니다.");
    },
    onCopyError: function () {
      this.$toasted.global.error("복사를 실패했습니다.");
    },
    close() {
      this.dialog = false;
    },
  },
  data() {
    return {
      fullscreen: false,
      dialog: false,

      item: {},
    };
  },
};
</script>

<style></style>
