<template>
  <div>
    <v-container fluid class="background">
      <v-row style="max-width: 1080px" class="mx-auto my-12">
        <v-col cols="12" align-self="center">
          <v-card class="transparent" style="" flat>
            <v-card-title class="font-weight-bold display-1">
              포트폴리오
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              메이드올의 작품
            </v-card-subtitle>
            <v-card-text class="body-1"> </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="!$route.params.id">
          <v-data-iterator
            class="body-2"
            :loading="fetchLoading"
            :items="items"
            :page.sync="page"
            :options.sync="options"
            :server-items-length="totalCount"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            no-data-text="데이터가 없습니다"
            disable-sort
          >
            <template v-slot:no-data>
              <v-row class="font-weight-bold title justify-center">
                공개된 자료가 없습니다
              </v-row>
              <v-row class="justify-center">
                <v-btn
                  @click="$router.push('/page/')"
                  color="primary"
                  class="rounded-lg body-2 font-weight-bold mt-4"
                  depressed
                >
                  홈으로
                </v-btn>
              </v-row>
            </template>

            <template v-slot:loading>
              <v-row>
                <v-col v-for="index in 4" :key="index" cols="6" md="3">
                  <v-skeleton-loader class="rounded-lg" type="card" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:default="props">
              <v-row>
                <v-col cols="12" v-if="fetchLoading" class="text-center">
                  <v-row>
                    <v-col v-for="index in 4" :key="index" cols="6" md="3">
                      <v-skeleton-loader class="rounded-lg" type="card" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-else
                  v-for="item in props.items"
                  :key="item.name"
                  cols="6"
                  md="3"
                >
                  <portfolio-card-dialog :content="item" />
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <div class="mt-5">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
        <v-col v-else>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <span class="highlight title font-weight-bold onSurface--text">
                  {{ items[0].title }}
                </span>
                <v-spacer></v-spacer>
              </v-col>
              <v-col v-if="items[0].imageURL" cols="12" md="6">
                <v-card class="surface rounded-xl" flat>
                  <v-img :aspect-ratio="4 / 3" :src="items[0].imageURL" />
                </v-card>
              </v-col>
              <v-col class="mt-2" align-self="start" cols="12" md="6">
                <v-row>
                  <v-col cols="4" md="2" align-self="center"> 게시일 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].createdAt | moment("YYYY-MM-DD HH:mm") }}
                  </v-col>
                  <v-col cols="4" md="2" align-self="center"> 소요 비용 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    align-self="center"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].options.cost }}
                  </v-col>
                  <v-col cols="4" md="2" align-self="center"> 작업 방식 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    align-self="center"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].options.method }}
                  </v-col>
                  <v-col cols="4" md="2" align-self="center"> 작업 소재 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    align-self="center"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].options.material }}</v-col
                  >
                  <v-col cols="4" md="2" align-self="center"> 표면 가공 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    align-self="center"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].options.postprocess }}
                  </v-col>
                  <v-col cols="4" md="2" align-self="center"> 소요 기간 </v-col>
                  <v-col
                    cols="8"
                    md="10"
                    align-self="center"
                    class="font-weight-bold onSurface--text"
                  >
                    {{ items[0].options.time }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col class="pa-2" cols="12">
                <tiptap-viewer
                  :description="items[0].content"
                  :readOnly="true"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import setMeta from "@/utils/setMeta";
import PortfolioCardDialog from "@/components/Dialog/PortfolioCardDialog.vue";
import TiptapViewer from "@/components/Editor/TiptapViewer.vue";
/* eslint-disable vue/no-unused-components */
export default {
  components: { PortfolioCardDialog, TiptapViewer },
  beforeCreate() {
    setMeta({
      title: "작업 사례",
      description: "메이드올 시제품 서비스 작업 사례입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fportfolio.png?alt=media&token=bd272a4a-e4d7-4d46-b9af-920e6490a36e",
    });
  },
  data() {
    return {
      fetchLoading: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      totalCount: 0,
      items: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
    search(n, o) {
      if (n !== o) this.list();
    },
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      this.fetchLoading = true;
      this.$axios
        .get("portfolios/collection", {
          params: {
            offset: this.page > 0 ? (this.page - 1) * this.itemsPerPage : 0,
            limit: this.itemsPerPage,
            order: this.options.sortBy[0],
            sort: this.options.sortDesc[0] ? "desc" : "asc",
            search: this.$route.params.id,
          },
        })
        .then(({ data }) => {
          this.totalCount = data.totalCount;
          this.items = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
  },
};
</script>

<style></style>
